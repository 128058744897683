function truncateText(truncated, maxLength, link) {
  if (truncated.length > maxLength) {
    truncated = truncated.substr(0, maxLength) + '<a class="read_more" href="' + link + '">[MEHR]</a>';
  }
  return truncated;
}
window.addEventListener("DOMContentLoaded", function() {
  let news = document.querySelectorAll(".jludwig_news_text");
  if (news == null) {
    return;
  }
  for (let i = 0; i < news.length; i++) {
    let t = document.querySelectorAll(".jludwig_news_text")[i].childNodes[1].childNodes[0];
    let text = document.querySelectorAll(".jludwig_news_text")[i].childNodes[1];
    let link = t.getAttribute("href");
    text.innerHTML = truncateText(t.innerHTML, 100, link);
  }
});
