window.addEventListener("DOMContentLoaded", function() {
  let scripts = document.querySelectorAll("script");
  let loadedScripts = [];
  for (let i = 0; i < scripts.length; i++) {
    if (loadedScripts.includes(scripts[i].getAttribute("src"))) {
      scripts[i].remove();
    } else {
      loadedScripts.push(scripts[i].getAttribute("src"));
    }
  }
});
window.addEventListener("DOMContentLoaded", function() {
  let styles = document.querySelectorAll("link");
  let loadedStyles = [];
  for (let i = 0; i < styles.length; i++) {
    if (loadedStyles.includes(styles[i].getAttribute("href"))) {
      styles[i].remove();
    } else {
      loadedStyles.push(styles[i].getAttribute("href"));
    }
  }
});
window.addEventListener("DOMContentLoaded", function() {
  let scripts = document.querySelectorAll("script");
  for (let i = 0; i < scripts.length; i++) {
    scripts[i].setAttribute("defer", "defer");
  }
});
