function positionDivider(slider, divider, screenWidth, offset1, offset2) {
  if (slider) {
    setTimeout(function() {
      if (screenWidth >= 1080) {
        divider.style.top = slider.offsetHeight - offset1 + "px";
      } else {
        divider.style.top = slider.offsetHeight - offset2 + "px";
      }
    }, 500);
  }
}
window.addEventListener("DOMContentLoaded", function() {
  const divider = document.querySelector("#mk-shape-divider-4");
  const masterSlider = document.querySelector(".master-slider");
  const width = document.body.clientWidth;
  if (divider) {
    positionDivider(masterSlider, divider, width, 118, 0);
  }
});
window.addEventListener("orientationchange", function() {
  const divider = document.querySelector("#mk-shape-divider-4");
  const masterSlider = document.querySelector(".master-slider");
  const width = document.body.clientWidth;
  if (divider) {
    positionDivider(masterSlider, divider, width, 118, -210);
  }
});
